// 导入安装的axios
import axios from 'axios'

// 创建axios请求实例,并暴
const myaxios = axios.create({
    baseURL: 'https://xcx.9jtx.cn/zsxcx',
    // baseURL: 'http://127.0.0.1:8080',
    timeout: 500000,
    headers: { 'X-Custom-Header': 'foobar' }
});

//暴露接口
export default myaxios;
